export const contactInformation =
  {
    AndyPhoneNumber: '07815946340',
    AndyPhoneNumberlink: 'tel:07815946340',
    RichardPhoneNumber: '07870519614',
    RichardPhonelink: 'tel:07870519614',
    Email: 'tcland.enquiries@gmail.com',
    Emaillink: 'mailto:tcland.enquiries@gmail.com',
    FacebookLink: 'https://www.facebook.com/townandcountrybucks'
  }
